jQuery(document).ready(function(){
  // Banner background image
  var bg_img = jQuery('.banner-wrap .img-wrap img').attr('src');
  jQuery('.banner-wrap').css('background-image', 'url(' + bg_img + ')');

// Sticky Header
  jQuery(window).on('scroll', function() {
    var scrolled = jQuery(window).scrollTop();
    if(scrolled > 30) {
      jQuery('body').addClass('sticky-header');
    }
    else {
      jQuery('body').removeClass('sticky-header');
    }
  });
  // End 

  // Header dropdown js
  jQuery('.right-wrap .lang .icon').on('click', function(e) {
    e.stopPropagation();
    jQuery('.currency .option-dropdown').removeClass('show');
    jQuery(this).next('.option-dropdown').toggleClass('show');
  });

  jQuery('.right-wrap .currency .icon').on('click', function(e) {
    e.stopPropagation();
    jQuery('.lang .option-dropdown').removeClass('show');
    jQuery(this).next('.option-dropdown').toggleClass('show');
  });
  jQuery('body').on('click', function() {
    jQuery('.currency .option-dropdown').removeClass('show');
    jQuery('.lang .option-dropdown').removeClass('show');
  });
  jQuery('.option-dropdown').on('click', function(e) {
    e.stopPropagation();
  });

  // Toggle menu
  jQuery('body').click(function(){
    if (jQuery(window).width() <= 992) {
      jQuery('.nav-bar').slideUp();
    }
  });
  jQuery('.nav-bar').click(function(e){
    event.stopPropagation();
  });
  jQuery('.toggle-button').click(function() {
    if (jQuery(window).width() <= 992) {
      event.stopPropagation();
      jQuery('.nav-bar').slideToggle();
    }
  });

  jQuery('body').click(function(){
    jQuery('.submenu').slideUp();
  });
  jQuery('.toggle-button').click(function() {
    event.stopPropagation();
    jQuery('.nav-bar').toggleClass('side-menu');
      jQuery('.submenu').slideUp();
      jQuery('.sub-menu').removeClass('active');
  });
  jQuery('.close-btn a').click(function() {
    jQuery('.nav-bar').removeClass('side-menu');
  });
  jQuery('.sub-menu > a').click(function() {
    event.stopPropagation();
    jQuery(this).parent('.sub-menu').siblings().find('.submenu').slideUp();
    jQuery(this).parent('.sub-menu').siblings('.sub-menu').removeClass('active');
    jQuery(this).next('.submenu').slideToggle();
    jQuery(this).parent('.sub-menu').toggleClass('active');
  });
  jQuery('.nav-bar').click(function() {
    event.stopPropagation();
  }); 


  // Custom checkbox js
  jQuery('input[type="checkbox"]').wrap('<div class="input-rc"></div>');
  jQuery('.input-rc').append('<span class="input-rc-span"></span>');

  jQuery('.slider-wrap .items').slick({
    centerMode: true,
    slidesToShow: 1,
    arrows: false,
    dots: true,
    responsive: [
    {
      breakpoint: 992,
      settings: {
        centerMode: false
      }
    }
    ]
  });

  jQuery('.quickview a').click(function() {
    setTimeout(function(){
      jQuery('.product-slider .items').owlCarousel({
        loop: true,
        nav: true,
        autoplay: false,
        dots: true,
        autoWidth: false,
        slideBy: 1,
        items:1
      });
    }, 180);
  });

  quantity_input();


  // Custom Selectbox JS
  jQuery('.search-wrap select').each(function(){
    var jQuerythis = jQuery(this), numberOfOptions = jQuery(this).children('option').length;
  
    jQuerythis.addClass('select-hidden'); 
    jQuerythis.wrap('<div class="select"></div>');
    jQuerythis.after('<div class="select-styled"></div>');

    var jQuerystyledSelect = jQuerythis.next('div.select-styled');
    jQuerystyledSelect.text(jQuerythis.children('option').eq(0).text());
  
    var jQuerylist = jQuery('<ul />', {
        'class': 'select-options'
    }).insertAfter(jQuerystyledSelect);
  
    for (var i = 0; i < numberOfOptions; i++) {
        jQuery('<li />', {
            text: jQuerythis.children('option').eq(i).text(),
            rel: jQuerythis.children('option').eq(i).val()
        }).appendTo(jQuerylist);
    }
  
    var jQuerylistItems = jQuerylist.children('li');
  
    jQuerystyledSelect.click(function(e) {
        e.stopPropagation();
        jQuery('div.select-styled.active').not(this).each(function(){
            jQuery(this).removeClass('active').next('ul.select-options').hide();
        });
        jQuery(this).toggleClass('active').next('ul.select-options').toggle();
    });
  
    jQuerylistItems.click(function(e) {
        e.stopPropagation();
        jQuerystyledSelect.text(jQuery(this).text()).removeClass('active');
        jQuerythis.val(jQuery(this).attr('rel'));
        jQuerylist.hide();
        //console.log(jQuerythis.val());
    });
    jQuery('.select-options li').click(function(){
      jQuery('.select-options li').removeClass('active');
      jQuery(this).addClass('active');
    });
    jQuery(document).click(function() {
        jQuerystyledSelect.removeClass('active');
        jQuerylist.hide();
    });
  });


  // Top Collections slider
  jQuery('.collection-slider .items').owlCarousel({
    loop: true,
    nav: true,
    autoplay: true,
    dots: false,
    autoWidth: false,
    slideBy: 5,
    items: 5,
    margin: 30
  });

  // Tabs JS
  jQuery('.mobile-tabs-wrap li a').click(function() {
    var target = "#" + this.getAttribute('data-target');
    jQuery('.mobile-tabs-wrap li a').removeClass('active');
    jQuery('.tabs-con').removeClass('active');
    jQuery(this).addClass('active');
    jQuery('body').find(target).addClass('active');
  });

});

// Custom input type number js
function quantity_input() {
  jQuery('<div class="quantity-nav"><div class="quantity-button quantity-down">-</div><div class="quantity-button quantity-up">+</div></div>').one().insertBefore('.qty');
  jQuery('.qty, .quantity-nav').one().wrapAll('<div class="quantity"></div>');

  jQuery('.quantity').each(function() {
    var spinner = jQuery(this),
      input = spinner.find('.qty'),
      btnUp = spinner.find('.quantity-up'),
      btnDown = spinner.find('.quantity-down'),
      min = input.attr('min'),
      max = input.attr('max');

    btnUp.click(function() {
      var oldValue = parseFloat(input.val());
      if (oldValue >= max) {
        var newVal = oldValue;
      } else {
        var newVal = oldValue + 1;
      }
      spinner.find(".qty").val(newVal);
      spinner.find(".qty").click("change");
    });

    btnDown.click(function() {
      var oldValue = parseFloat(input.val());
      if (oldValue <= min) {
        var newVal = oldValue;
      } else {
        var newVal = oldValue - 1;
      }
      spinner.find(".qty").val(newVal);
      spinner.find(".qty").click("change");
    });
  });
}

